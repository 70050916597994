<template>
  <div class="momentList-item">
    <div class="moment-info" @click="goinMomentsDetail">
      <div class="image-container">
        <el-image class="image" :src="listItem.coverPicture"> </el-image>

        <el-image
          v-if="listItem.fileType"
          class="play-icon"
          :src="require('@//assets/img/play.png')"
        ></el-image>
      </div>

      <div class="info">
        <span v-if="!listItem.title" class="no-title">{{ listItem.subject }}</span>

        <div v-if="listItem.title" class="moments-title">
          {{ listItem.title }}
        </div>
        <div v-if="listItem.title" class="moments-content">
          {{ listItem.subject }}
        </div>

        <div class="second-row">
          <span>作者：</span>
          <el-image class="avatar" :src="listItem.userAvatarUrl"></el-image>
          <span style="margin-left: 5px"
            >{{ listItem.userName }} &nbsp;| &nbsp;作者ID：{{ listItem.userId }}</span
          >
        </div>

        <div class="third-row">
          <span>发布时间：{{ listItem.releaseTime }}</span>
        </div>
      </div>

      <div class="state-container">
        <el-image
          v-if="listItem.auditStatus == 0 && listItem.isReport != 1"
          class="examine-state"
          :src="require('@/assets/img/wait-examine.png')"
        ></el-image>

        <el-image
          v-if="listItem.auditStatus == 1"
          class="examine-state"
          :src="require('@/assets/img/passed.png')"
        ></el-image>

        <el-image
          v-if="listItem.isReport == 1"
          class="examine-state"
          :src="require('@/assets/img/reported.png')"
        ></el-image>
      </div>
    </div>

    <div class="data-info">
      <div class="hot-data">
        <div class="first-row">
          <svg
            t="1657783918080"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5137"
            width="18"
            height="20"
          >
            <path
              d="M448.533333 155.797333c67.968 53.098667 115.498667 104.618667 142.592 154.602667 25.664 47.36 35.968 95.808 30.912 145.344l-0.981333 8.256 5.034667-4.117333a143.829333 143.829333 0 0 0 40.533333-57.536l2.218667-6.122667 6.336-19.029333c67.776 46.037333 101.674667 124.010667 101.674666 233.898666 0 164.821333-144.426667 270.037333-263.125333 270.037334-118.72 0-253.866667-70.506667-275.733333-218.069334-21.845333-147.541333 68.757333-216.426667 130.474666-312.533333 41.173333-64.064 67.84-128.981333 80.064-194.730667z"
              p-id="5138"
              fill="#999999"
            ></path>
          </svg>
          <span>热度</span>
        </div>
        <div class="second-row">{{ listItem.heat }}</div>
      </div>

      <div class="right">
        <div class="first-col">
          <div class="item">
            <i style="position: relative; bottom: -2px" class="el-icon-view"></i>
            <span class="text">浏览</span>
            <span class="num">{{ listItem.browseNum }}</span>
          </div>

          <div class="item" style="margin-top: 12px">
            <svg
              t="1657788839323"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="8135"
              width="16"
              height="16"
            >
              <path
                d="M157.568 751.296c-11.008-18.688-18.218667-31.221333-21.802667-37.909333A424.885333 424.885333 0 0 1 85.333333 512C85.333333 276.362667 276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667-191.029333 426.666667-426.666667 426.666667a424.778667 424.778667 0 0 1-219.125333-60.501334 2786.56 2786.56 0 0 0-20.053334-11.765333l-104.405333 28.48c-23.893333 6.506667-45.802667-15.413333-39.285333-39.296l28.437333-104.288z m65.301333 3.786667l-17.258666 63.306666 63.306666-17.258666a32 32 0 0 1 24.522667 3.210666 4515.84 4515.84 0 0 1 32.352 18.944A360.789333 360.789333 0 0 0 512 874.666667c200.298667 0 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333 149.333333 311.701333 149.333333 512c0 60.586667 14.848 118.954667 42.826667 171.136 3.712 6.912 12.928 22.826667 27.370667 47.232a32 32 0 0 1 3.338666 24.714667z m145.994667-70.773334a32 32 0 1 1 40.917333-49.205333A159.189333 159.189333 0 0 0 512 672c37.888 0 73.674667-13.173333 102.186667-36.885333a32 32 0 0 1 40.917333 49.216A223.178667 223.178667 0 0 1 512 736a223.178667 223.178667 0 0 1-143.136-51.690667z"
                p-id="8136"
                fill="#999999"
              ></path>
            </svg>
            <span class="text">评论</span>
            <span class="num">{{ listItem.commentNum }}</span>
          </div>
        </div>
        <div class="second-col">
          <div class="item">
            <svg
              t="1657788467832"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="7133"
              width="16"
              height="16"
            >
              <path
                d="M510.68 883.15c-15.2 0-30.37-8.49-48.1-25.47-25.35-24.3-50.81-48.48-76.27-72.64-61.6-58.49-125.28-118.96-186.38-180.25-68.39-68.6-99.26-141.23-94.39-222.07 4.09-67.79 31.08-122.65 78.06-158.66 50.62-38.79 123.3-53.23 194.46-38.6 51.71 10.63 90 41.18 127.03 70.72l1.54 1.23c0.47 0.38 0.94 0.76 1.41 1.13 8.05-5.05 15.94-10.15 23.68-15.13 30.01-19.35 58.34-37.63 90.38-50.54 84.26-33.9 189.34-8.11 244.51 60.07 58.08 71.79 68.23 157.45 28.57 241.22-20 42.22-50.67 84.68-91.16 126.22-57.91 59.41-118.94 117.32-177.96 173.33-22.3 21.16-44.59 42.32-66.77 63.59-17.98 17.22-33.31 25.85-48.61 25.85zM322.91 235.07c-40.08 0-77.72 11.4-105.7 32.85-34.37 26.34-53.38 66.08-56.52 118.12-3.92 65.06 20.98 122.17 78.37 179.73 60.56 60.74 123.97 120.95 185.3 179.18 25.52 24.23 51.04 48.47 76.45 72.82 4.78 4.57 7.91 7.21 9.9 8.73 2.06-1.55 5.31-4.3 10.31-9.09 22.25-21.35 44.61-42.57 66.98-63.8 58.64-55.64 119.28-113.19 176.44-171.82 36.23-37.15 63.39-74.6 80.77-111.3 30.5-64.42 23.05-127.64-21.58-182.8-39.86-49.23-119.33-68.36-180.89-43.55-27.16 10.93-53.36 27.83-81.09 45.71-13.56 8.74-27.59 17.79-42.49 26.65l-17.29 10.29-18.13-16.06c-2.81-2.58-7.07-6.51-11.59-10.12l-1.54-1.22c-33.41-26.65-64.96-51.83-103.69-59.79a217.005 217.005 0 0 0-44.01-4.53z"
                p-id="7134"
                fill="#999999"
              ></path>
            </svg>
            <span class="text">喜欢</span>
            <span class="num">{{ listItem.goodsNum }}</span>
          </div>

          <div class="item" style="margin-top: 12px">
            <svg
              t="1657788987965"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9129"
              width="15"
              height="15"
            >
              <path
                d="M928 480c-17.664 0-32 14.336-32 32l0 320c0 17.664-14.336 32-32 32L160 864c-17.664 0-32-14.336-32-32L128 288c0-17.664 14.336-32 32-32l224 0 0 0c0 0 0 0 0 0 17.664 0 32-14.336 32-32s-14.336-32-32-32c-1.024 0-1.888 0.48-2.88 0.576L160.384 192.576c-52.96 0-95.904 43.008-95.904 96.064l0 543.872c0 53.056 42.944 96.064 95.904 96.064l704.224 0c52.96 0 95.904-43.008 95.904-96.064L960.512 512 960 512C960 494.336 945.664 480 928 480zM402.688 620.768c-1.92 4.128-3.168 8.608-3.168 13.44 0 17.664 14.336 32 32 32 15.456 0 27.744-11.168 30.752-25.76l0.64 0c37.12-189.696 193.984-336.256 388.672-358.272l-67.776 67.776c-12.512 12.512-12.512 32.768 0 45.248 12.512 12.512 32.768 12.512 45.248 0l122.528-122.528c12.512-12.512 12.512-32.768 0-45.248l-122.528-122.528c-12.512-12.512-32.768-12.512-45.248 0-12.512 12.512-12.512 32.768 0 45.248l67.52 67.52C628.256 239.872 448.224 405.408 402.688 620.768z"
                p-id="9130"
                fill="#999999"
              ></path>
            </svg>
            <span class="text">分享</span>
            <span class="num">{{ listItem.shareNum }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="button-area">
      <div class="first-row">
        <el-button
          v-if="listItem.isTop && Number(orderType) == 1"
          size="mini"
          type="success"
          plain
          @click="doTop"
          >取消置顶</el-button
        >
        <el-button
          v-if="!listItem.isTop && Number(orderType) == 1"
          size="mini"
          type="success"
          plain
          style="width: 76px"
          @click="doTop"
          >置顶</el-button
        >
        <el-button
          v-if="listItem.isChoice == 1"
          size="mini"
          type="success"
          plain
          style="width: 76px"
          @click="doChoice"
          >取消精选</el-button
        >
        <el-button
          v-if="listItem.isChoice != 1"
          size="mini"
          type="success"
          plain
          style="width: 76px"
          @click="doChoice"
          >精选</el-button
        >
        <el-button size="mini" type="success" plain @click="goinMomentsDetail">详情</el-button>
      </div>

      <div v-if="listItem.isReport" class="second-row">
        <el-badge
          v-if="listItem.isReport && listItem.topicCommentReportNum"
          :value="listItem.topicCommentReportNum"
          :max="99"
          class="badge-button"
        >
          <el-button size="mini" type="danger" style="width: 94px" plain @click="showDialog(1)"
            >评论被举报</el-button
          >
        </el-badge>

        <el-badge
          v-if="listItem.isReport && listItem.topicReportNum"
          :value="listItem.topicReportNum"
          :max="99"
        >
          <el-button size="mini" type="danger" style="width: 94px" plain @click="showDialog(0)"
            >动态被举报</el-button
          >
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    listItem: {
      type: Object,
      default: function () {
        return {}
      },
    },
    orderType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    showDialog(type) {
      // type 1:评论被举报 0：动态被举报
      this.$emit('showDialog', { type, topicId: this.listItem.topicId })
    },

    doTop() {
      let type = 0
      if (this.listItem.isTop) type = 0 // 0:取消置顶操作，1：置顶操作
      if (!this.listItem.isTop) type = 1

      if (Number(this.orderType) === 1)
        this.$emit('doTop', { topicId: this.listItem.topicId, type })
    },

    doChoice() {
      let type = 0

      if (this.listItem.isChoice === 1) type = 0 // 0:取消精选操作，1：设为精选操作
      if (this.listItem.isChoice !== 1) type = 1

      this.$emit('doChoice', { topicId: this.listItem.topicId, type })
    },

    goinMomentsDetail() {
      window.open(
        window.location.href.replace('momentsList', 'momentsDetail') +
          `?id=${this.listItem.topicId}`,
      )
    },
  },
}
</script>

<style lang="less" scoped>
.momentList-item {
  display: flex;
  padding: 20px 0 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: #ffffff;
  border-radius: 6px;

  .moment-info {
    display: inline-flex;
    flex: 1;

    .image-container {
      position: relative;

      .image {
        width: 84px;
        height: 84px;
      }

      .play-icon {
        height: 14px;
        width: 14px;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
      }

      .play-icon::after {
        content: '';
        width: 28px;
        height: 28px;
        background: #000000;
        opacity: 26%;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      flex: 1;

      .no-title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      .moments-title {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }

      .moments-content {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }

      .second-row {
        font-size: 12px;
        font-weight: 400;
        color: #b5b8bb;
        margin-top: 10px;

        .avatar {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          bottom: -2px;
        }
      }

      .third-row {
        font-size: 12px;
        font-weight: 400;
        color: #b5b8bb;
        margin-top: 7px;
      }
    }

    .state-container {
      margin-right: 36px;
      margin-left: 20px;
      display: flex;
      align-items: center;

      .examine-state {
        width: 54px;
        height: 54px;
      }
    }
  }

  .moment-info::after {
    content: '';
    width: 1px;
    // height: 84px;
    background: #e0e0e0;
  }

  .data-info {
    display: flex;
    width: 326px;

    .hot-data {
      width: 112px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .first-row {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }

      .second-row {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .right {
      margin-left: 10px;
      display: flex;
      flex: 1;

      .first-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .second-col {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .item {
        display: flex;
        color: #999999;
        align-items: flex-end;

        .text {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-left: 2px;
        }

        .num {
          color: #333333;
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .data-info::after {
    content: '';
    width: 1px;
    // height: 84px;
    background: #e0e0e0;
    position: relative;
    right: 0px;
  }

  .button-area {
    width: 338px;
    display: inline-flex;
    max-width: 338px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .first-row {
      margin-left: 30px;
    }

    .second-row {
      margin-left: 30px;
      margin-top: 15px;

      .badge-button {
        margin-right: 10px;
      }

      ::v-deep .el-badge__content.is-fixed {
        right: 20px;
        z-index: 99;
      }
    }

    ::v-deep .el-button--mini {
      padding: 4px 10px;
      font-size: 14px;
    }

    ::v-deep .el-button--success.is-plain {
      color: #2861e2;
      background: #ffffff;
      border-color: #2861e2;
    }

    ::v-deep .el-button--danger.is-plain {
      color: #e86247;
      background: #ffffff;
      border-color: #e86247;
    }
  }
}
</style>
