<template>
  <div class="reportList-item">
    <div class="comment-info">
      <span class="avatar">
        <el-image class="image" :src="listItem.reportUserAvatarUrl"></el-image>
      </span>

      <span class="info">
        <div class="first-row">
          <span class="userName">{{ listItem.reportUserName }}</span>
          <span class="userId">用户ID：{{ listItem.reportUserId }}</span>
        </div>
        <div class="second-row">{{ listItem.commentSubject }}</div>
        <div class="third-row">{{ listItem.createTime }}</div>
      </span>
    </div>

    <div class="report-info">
      <div class="first-row">
        举报类型：<span style="color: #e86247">{{ listItem.reportContent }}</span>
      </div>
      <div class="second-row">
        <span style="white-space: nowrap">举报描述：</span>
        <span class="description-content">{{ listItem.reportDec || '未填写举报描述' }}</span>
      </div>
    </div>

    <div class="button-area">
      <div class="first-row">
        <el-button size="mini" type="primary" style="width: 48px" @click="pass">通过</el-button>
        <el-button size="mini" type="danger" plain style="width: 48px" @click="mask">屏蔽</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listItem: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    showDialog(type) {
      this.$emit('showDialog', type)
    },

    pass() {
      this.$emit('reportPass', { commentId: this.listItem.topicCommentId, topicId: this.listItem.topicId })
    },

    mask() {
      this.$emit('reportMask', { commentId: this.listItem.topicCommentId, topicId: this.listItem.topicId })
    },
  },
}
</script>

<style lang="less" scoped>
.reportList-item {
  display: flex;
  padding: 20px 0 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: #ffffff;
  border-radius: 6px;

  .comment-info {
    display: inline-flex;
    flex: 1;
    position: relative;

    .image {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      flex: 1;
      margin-right: 104px;

      .first-row {
        .userName {
          font-size: 13px;
          font-weight: 500;
          color: #a2a2a2;
        }

        .userId {
          margin-left: 10px;
          background: #f7f7f7;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #777777;
        }
      }

      .second-row {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
      }

      .third-row {
        font-size: 12px;
        font-weight: 400;
        color: #a2a2a2;
        margin-top: 8px;
      }
    }

    .examine-state {
      width: 54px;
      height: 54px;
      margin-right: 36px;
      margin-left: 20px;
    }
  }

  .comment-info::after {
    content: '';
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .report-info {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;

    .first-row {
      font-size: 14px;
      color: #333333;
    }

    .second-row {
      display: flex;
    }
  }

  .report-info::after {
    content: '';
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .button-area {
    width: 208px;
    display: inline-flex;
    align-items: center;

    .first-row {
      margin-left: 30px;
    }

    ::v-deep .el-button--mini {
      padding: 4px 10px;
      font-size: 14px;
    }

    ::v-deep .el-button--success.is-plain {
      color: #2861e2;
      background: #ffffff;
      border-color: #2861e2;
    }

    ::v-deep .el-button--danger.is-plain {
      color: #e86247;
      background: #ffffff;
      border-color: #e86247;
    }
  }
}
</style>
