import MomentListItem from '../components/MomentListItem'
import ReportListItem from '../components/ReportListItem'
import MaskListItem from '../components/MaskListItem'
import {
  getMomentsList,
  momentsOperate,
  reportRecordList,
  commentMaskOrPass,
  getCommentList,
} from '@/api/friendCircle'

export default {
  name: '',
  components: {
    MomentListItem,
    ReportListItem,
    MaskListItem,
  },
  data() {
    return {
      // 动态列表分页
      total: 0,
      pageSize: 10,
      currentPage: 1,
      // 举报列表分页
      reportTotal: 0,
      reportPageSize: 10,
      reportCurrentPage: 1,

      orderType: '2', // order-tab类型 1:精选 2：全部 3：已屏蔽
      momentOrComment: '', // 0:已屏蔽（动态） 1:已屏蔽（评论）
      showReportDialog: false,
      reportType: '', // 0:被举报的动态， 1：被举报的评论
      currentMaskType: 1, // 评论 1:人工屏蔽，2:机器屏蔽 动态 2:人工屏蔽 3:机器屏蔽
      currentTopicId: 0,
      empty: '', // 1:精选 全部 已屏蔽 空状态
      dialogEmpty: false,

      timePickerValue: [],

      searchForm: {
        key: '',
        sendTimeStart: '',
        sendTimeEnd: '',
        auditStatus: '',
      },
      options: [
        { value: 0, label: '待审核' },
        { value: 1, label: '已通过' },
        { value: 4, label: '被举报' },
      ],
      tabOptions: [
        { value: 0, label: '已屏蔽（动态）' },
        { value: 1, label: '已屏蔽（评论）' },
      ],
      momentsList: [],
      reportList: [],
      maskMomentsList: [],
      maskCommentList: [],
    }
  },

  mounted() {
    this.getMomentsList()
  },

  methods: {
    timechange() {
      this.searchForm.sendTimeStart = this.timePickerValue[0]
      this.searchForm.sendTimeEnd = this.timePickerValue[1]
    },

    getMomentsList() {
      const reqObj = {
        data: {
          ...this.searchForm,
          type: Number(this.orderType),
        },
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      }

      getMomentsList(reqObj).then((res) => {
        console.log(res)

        const { success, data } = res

        if (success) {
          this.momentsList = data.content
          this.total = Number(data.total)

          if (!Number(data.total)) {
            this.empty = 1
          }
        }
      })
    },

    switchTab(tab, event) {
      // console.log(tab, event);
      this.momentOrComment = ''
      this.currentPage = 1
      this.pageSize = 10
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.timePickerValue = []
      this.empty = ''

      if (this.orderType === 1) {
        console.log('精选')

        this.getMomentsList()
      }

      if (this.orderType === 2) {
        console.log('全部')

        this.getMomentsList()
      }
    },

    // 全部 精选 已屏蔽 分页SizeChange
    momentsSizeChange(val) {
      this.pageSize = val

      if (this.orderType !== 3) {
        this.getMomentsList()
      } else {
        if (!this.momentOrComment) {
          this.getMaskMomentsList()
        }

        if (this.momentOrComment) this.getMaskCommentList()
      }
    },

    // 全部 精选 已屏蔽 分页CurrentChange
    momentsCurrentChange(val) {
      this.currentPage = val

      if (this.orderType !== 3) {
        this.getMomentsList()
      } else {
        if (!this.momentOrComment) this.getMaskMomentsList()

        if (this.momentOrComment) this.getMaskCommentList()
      }
    },

    // 被举报评论分页
    reportSizeChange(val) {
      this.reportPageSize = val

      this.getReportList(this.currentTopicId)
    },

    // 被举报评论分页
    reportCurrentChange(val) {
      this.reportCurrentPage = val
      this.getReportList(this.currentTopicId)
    },

    // 切换已屏蔽列表，默认人工，切换momentOrComment
    tabSelectChange(val) {
      this.total = 0
      this.orderType = '3'
      this.currentMaskType = 1
      this.currentPage = 1
      this.pageSize = 10
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.timePickerValue = []
      this.empty = ''

      if (!val) {
        console.log('已屏蔽（动态）', this.momentOrComment)
        this.getMaskMomentsList()
      }

      if (val) {
        console.log('已屏蔽（评论）', this.momentOrComment)
        this.getMaskCommentList()
      }
    },

    dialogClose(done) {
      this.showReportDialog = false
      this.dialogEmpty = false

      this.getMomentsList()
    },

    showDialog(para) {
      const { type, topicId } = para

      this.reportType = type
      this.currentTopicId = topicId

      // this.getReportList(topicId);

      if (type) {
        this.showReportDialog = true
        this.getReportList(topicId)
      }

      if (!type) {
        window.open(window.location.href.replace('momentsList', 'momentsDetail') + `?id=${topicId}`)
      }
    },

    // 已屏蔽列表，人工屏蔽和机器屏蔽状态切换
    switchMask(type) {
      // console.log(type);
      this.total = 0
      this.currentPage = 1
      this.pageSize = 10
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.timePickerValue = []
      this.empty = ''

      // 切换为机器屏蔽
      if (type === 1) {
        this.currentMaskType = 2

        // 已屏蔽动态
        if (!this.momentOrComment) {
          this.getMaskMomentsList()
        }

        // 已屏蔽评论
        if (this.momentOrComment) {
          this.getMaskCommentList()
        }
      }

      // 切换为人工屏蔽
      if (type === 2) {
        this.currentMaskType = 1

        // 已屏蔽动态
        if (!this.momentOrComment) {
          this.getMaskMomentsList()
        }

        // 已屏蔽评论
        if (this.momentOrComment) {
          this.getMaskCommentList()
        }
      }
    },

    search() {
      this.currentPage = 1
      this.pageSize = 10
      this.getMomentsList()
    },

    resetSearch() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm)

      this.timePickerValue = []

      this.currentPage = 1
      this.pageSize = 10
      this.empty = ''

      this.getMomentsList()
    },

    doTop(para) {
      const { topicId, type } = para
      const reqObj = {
        data: {
          topicId,
          check: 3,
        },
      }
      momentsOperate(reqObj).then((res) => {
        if (res.success) {
          this.getMomentsList()
          if (type) {
            this.$message({
              message: '置顶成功',
              type: 'success',
            })
          }
          if (!type) {
            this.$message({
              message: '已取消置顶',
              type: 'success',
            })
          }
        }
      })
    },

    doChoice(para) {
      const { topicId, type } = para

      const reqObj = {
        data: {
          topicId,
          check: 4,
        },
      }

      momentsOperate(reqObj).then((res) => {
        if (res.success) {
          this.getMomentsList()

          if (type) {
            this.$message({
              message: '加入精选成功',
              type: 'success',
            })
          }

          if (!type) {
            this.$message({
              message: '已取消精选',
              type: 'success',
            })
          }
        }
      })
    },

    getReportList(topicId) {
      const reqObj = {
        data: {
          topicId,
          reportType: this.reportType,
        },
        pageNumber: this.reportCurrentPage,
        pageSize: this.reportPageSize,
      }

      reportRecordList(reqObj).then((res) => {
        const { success, data } = res
        if (success) {
          this.reportTotal = Number(data.total)
          this.reportList = data.content

          if (res.data.empty) {
            this.dialogEmpty = true
          }
        }
      })
    },

    // 通过被举报评论
    reportPass(para) {
      const { commentId, topicId } = para

      const reqObj = {
        data: {
          commentId,
          isShield: 0,
        },
      }

      commentMaskOrPass(reqObj).then((res) => {
        if (res.success) {
          this.getReportList(topicId)

          this.$message({
            message: '审核通过',
            type: 'success',
          })
        }
      })
    },

    // 屏蔽被举报评论
    reportMask(para) {
      const { commentId, topicId } = para

      const reqObj = {
        data: {
          commentId,
          isShield: 1,
        },
      }

      commentMaskOrPass(reqObj).then((res) => {
        if (res.success) {
          this.getReportList(topicId)

          this.$message({
            message: '屏蔽成功',
            type: 'success',
          })
        }
      })
    },

    // 获取已屏蔽评论列表
    getMaskCommentList() {
      const reqObj = {
        data: {
          key: this.searchForm.key,
          sendTimeStart: this.searchForm.sendTimeStart,
          sendTimeEnd: this.searchForm.sendTimeEnd,
          auditStatus: this.currentMaskType,
        },
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      }

      getCommentList(reqObj).then((res) => {
        if (res.success) {
          this.maskCommentList = res.data.content
          this.total = Number(res.data.total)

          if (res.empty) {
            this.empty = 1
          }
        }
      })
    },

    // 获取已屏蔽动态列表
    getMaskMomentsList() {
      const reqObj = {
        data: {
          type: 3,
          key: this.searchForm.key,
          sendTimeStart: this.searchForm.sendTimeStart,
          sendTimeEnd: this.searchForm.sendTimeEnd,
          auditStatus: this.currentMaskType + 1, // 因为动态 2是人工屏蔽，3是机器屏蔽
        },
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      }

      getMomentsList(reqObj).then((res) => {
        console.log(res)
        if (!res.success) return
        this.maskMomentsList = res.data.content
        this.total = Number(res.data.total)

        if (res.data.empty) {
          this.empty = 1
        }
      })
    },

    // 通过已屏蔽评论
    passMaskComment(para) {
      const { commentId } = para

      const reqObj = {
        data: {
          commentId,
          isShield: 0,
        },
      }

      commentMaskOrPass(reqObj).then((res) => {
        if (res.success) {
          this.getMaskCommentList()

          this.$message({
            message: '审核通过',
            type: 'success',
          })
        }
      })
    },
  },
}
