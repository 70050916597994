<template>
  <div class="maskList-item">
    <div class="mask-info" @click="goinMomentsDetail">
      <template v-if="momentOrComment">
        <span class="avatar">
          <el-image class="image" :src="maskCommentItem.avatarUrl"></el-image>
        </span>

        <span class="info-comment">
          <div class="first-row">
            <span class="userName">{{ maskCommentItem.userName }}</span>
            <span class="userId">用户ID：{{ maskCommentItem.userId }}</span>
          </div>
          <div class="second-row">{{ maskCommentItem.subject }}</div>
          <div class="third-row">{{ maskCommentItem.createTime }}</div>
        </span>
      </template>

      <template v-else>
        <div>
          <el-image class="image" :src="maskMomentsItem.coverPicture"></el-image>
        </div>

        <div class="info-moment">
          <span v-if="!maskMomentsItem.title" class="no-title">{{ maskMomentsItem.subject }}</span>

          <div v-if="maskMomentsItem.title" class="moments-title">
            {{ maskMomentsItem.title }}
          </div>
          <div v-if="maskMomentsItem.title" class="moments-content">
            {{ maskMomentsItem.subject }}
          </div>

          <div class="second-row">
            <span>作者：</span>
            <el-image class="avatar" :src="maskMomentsItem.userAvatarUrl"></el-image>
            <span style="margin-left: 5px"
              >{{ maskMomentsItem.userName }} &nbsp;| &nbsp;作者ID：{{ maskMomentsItem.userId }}</span
            >
          </div>

          <div class="third-row">
            <span>发布时间：{{ maskMomentsItem.releaseTime }}</span>
          </div>
        </div>
      </template>
    </div>

    <div class="button-area">
      <div class="first-row">
        <el-button
          v-if="momentOrComment && currentMaskType == 1"
          size="mini"
          type="primary"
          style="width: 48px"
          @click="passComment"
          >通过</el-button
        >
        <el-button size="mini" type="primary" plain style="width: 48px" @click="goinMomentsDetail">详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    momentOrComment: {
      type: Number,
      default: 0,
    },
    currentMaskType: {
      type: Number,
      default: 0,
    },
    maskMomentsItem: {
      type: Object,
      default: function () {
        return {}
      },
    },
    maskCommentItem: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    goinMomentsDetail() {
      if (!this.momentOrComment) {
        window.open(
          window.location.href.replace('momentsList', 'momentsDetail') + `?id=${this.maskMomentsItem.topicId}`
        )
      }

      if (this.momentOrComment) {
        window.open(
          window.location.href.replace('momentsList', 'momentsDetail') + `?id=${this.maskCommentItem.topicId}`
        )
      }
    },

    passComment() {
      this.$emit('passMaskComment', {
        commentId: this.maskCommentItem.commentId,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.maskList-item {
  display: flex;
  padding: 20px 0 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: #ffffff;
  border-radius: 6px;

  .mask-info {
    display: inline-flex;
    flex: 1;
    position: relative;

    .avatar {
      .image {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }

    .info-comment {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      flex: 1;
      margin-right: 104px;

      .first-row {
        .userName {
          font-size: 13px;
          font-weight: 500;
          color: #a2a2a2;
        }

        .userId {
          margin-left: 10px;
          background: #f7f7f7;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #777777;
          padding: 0 5px;
        }
      }

      .second-row {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
      }

      .third-row {
        font-size: 12px;
        font-weight: 400;
        color: #a2a2a2;
        margin-top: 8px;
      }
    }

    .image {
      width: 84px;
      height: 84px;
    }

    .info-moment {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      flex: 1;
      margin-right: 300px;

      .no-title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      .moments-title {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }

      .moments-content {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 1; //行数
        line-clamp: 1;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }

      .second-row {
        font-size: 12px;
        font-weight: 400;
        color: #b5b8bb;
        margin-top: 10px;

        .avatar {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          bottom: -2px px;
        }
      }

      .third-row {
        font-size: 12px;
        font-weight: 400;
        color: #b5b8bb;
        margin-top: 7px;
      }
    }
  }

  .mask-info::after {
    content: '';
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .button-area {
    width: 208px;
    display: inline-flex;
    align-items: center;

    .first-row {
      margin-left: 30px;
    }

    ::v-deep .el-button--mini {
      padding: 4px 10px;
      font-size: 14px;
    }

    ::v-deep .el-button--primary.is-plain {
      color: #2861e2;
      background: #ffffff;
      border-color: #2861e2;
    }
  }
}
</style>
