<template>
  <div class="momentsList">
    <el-tabs v-model="orderType" class="order-tabs" @tab-click="switchTab">
      <el-tab-pane label="精选" name="1"></el-tab-pane>
      <el-tab-pane label="全部" name="2"></el-tab-pane>
      <el-tab-pane name="3" disabled>
        <div slot="label" class="select-label">
          <el-select
            v-model="momentOrComment"
            style="width: 110px"
            placeholder="已屏蔽（动态）"
            @change="tabSelectChange"
          >
            <el-option
              v-for="item in tabOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="list-container">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="关键词：">
          <el-input
            v-model.trim="searchForm.key"
            class="input"
            size="mini"
            :placeholder="
              '请输入' + (momentOrComment == 1 ? '评论' : '动态') + '关键词'
            "
          ></el-input>
        </el-form-item>

        <el-form-item label="发布时间">
          <el-date-picker
            v-model="timePickerValue"
            style="width: 346px"
            type="datetimerange"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            size="mini"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="timechange"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item v-if="orderType != 3" label="审核状态">
          <el-select
            v-model="searchForm.auditStatus"
            size="mini"
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            class="button"
            type="primary"
            size="mini"
            style="margin-left: 0px"
            @click="search"
            >查询</el-button
          >
          <el-button
            size="mini"
            class="button"
            icon="el-icon-refresh-left"
            @click="resetSearch"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <template v-if="orderType != 3 && !empty">
        <MomentListItem
          v-for="(item, index) in momentsList"
          :key="index"
          :list-item="item"
          :order-type="orderType"
          @showDialog="showDialog"
          @doChoice="doChoice"
          @doTop="doTop"
        ></MomentListItem>
      </template>

      <template v-if="orderType == 3">
        <div style="display: flex; height: 74px; background: #f7f7f7">
          <div class="mask-type">
            <el-button v-if="currentMaskType == 1" type="primary" size="small"
              >人工屏蔽</el-button
            >
            <el-button
              v-if="currentMaskType == 2"
              type="info"
              size="small"
              @click="switchMask(2)"
              >人工屏蔽</el-button
            >
            <el-button v-if="currentMaskType == 2" type="primary" size="small"
              >机器屏蔽</el-button
            >
            <el-button
              v-if="currentMaskType == 1"
              type="info"
              size="small"
              @click="switchMask(1)"
              >机器屏蔽</el-button
            >
          </div>
        </div>

        <template v-if="!empty && !momentOrComment"
          ><MaskListItem
            v-for="(item, index) in maskMomentsList"
            :key="index"
            :moment-or-comment="momentOrComment"
            :current-mask-type="currentMaskType"
            :mask-moments-item="item"
            @passMaskComment="passMaskComment"
          ></MaskListItem
        ></template>

        <template v-if="!empty && momentOrComment"
          ><MaskListItem
            v-for="(item, index) in maskCommentList"
            :key="index"
            :moment-or-comment="momentOrComment"
            :current-mask-type="currentMaskType"
            :mask-comment-item="item"
            @passMaskComment="passMaskComment"
          ></MaskListItem
        ></template>

        <template v-if="empty == 1">
          <el-empty
            v-if="!momentOrComment"
            class="empty"
            description="暂无已屏蔽动态"
          ></el-empty>

          <el-empty
            v-if="momentOrComment"
            class="empty"
            description="暂无已屏蔽评论"
          ></el-empty>
        </template>
      </template>

      <template v-if="empty == 1 && orderType != 3">
        <el-empty
          v-if="orderType == 2"
          class="empty"
          description="暂无动态"
        ></el-empty>
        <el-empty
          v-if="orderType == 1"
          class="empty"
          description="暂无精选动态"
        ></el-empty>
      </template>

      <el-pagination
        v-if="![1].includes(empty)"
        class="pagination"
        background
        :current-page="currentPage"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes"
        :total="total"
        @size-change="momentsSizeChange"
        @current-change="momentsCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 举报列表弹窗 -->
    <el-dialog
      :title="'被举报的' + (reportType == 1 ? '评论' : '动态')"
      :visible.sync="showReportDialog"
      width="70%"
      :before-close="dialogClose"
      class="report-dialog"
    >
      <div v-if="!dialogEmpty">
        <ReportListItem
          v-for="(item, index) in reportList"
          :key="index"
          :list-item="item"
          @reportPass="reportPass"
          @reportMask="reportMask"
        ></ReportListItem>
        <el-pagination
          class="pagination"
          background
          :current-page="reportCurrentPage"
          :page-sizes="[10, 50, 100, 200]"
          :page-size="reportPageSize"
          layout="prev, pager, next, jumper, sizes"
          :total="reportTotal"
          @size-change="reportSizeChange"
          @current-change="reportCurrentChange"
        >
        </el-pagination>
      </div>

      <div v-if="dialogEmpty">
        <el-empty class="empty" description="暂无举报记录"></el-empty>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexScript from "./index.js";

export default indexScript;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
